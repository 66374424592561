import React from 'react';
import StaffOnlyItem from "./ResultTypes/StaffOnly";

export const LinkHeading = ({ url, children, isStaffOnly, ...props }) => (
  <h3 className="gel-typography__link-heading">
    <a
      href={url}
      {...props}
      className={ isStaffOnly ? 'mr-6' : '' }
    >
      { children }
    </a>
    { isStaffOnly
      ? <StaffOnlyItem />
      : null
    }
  </h3>
);

export const SubHeading = ({ children, ...props }) => (
  <p
    className="gel-typography__sub-heading"
    {...props}
  >
    { children }
  </p>
);

export const Paragraph = ({ children, ...props }) => (
  <p
    className="gel-typography__paragraph"
    {...props}
  >
    { children }
  </p>
);

export const SidebarHeading = ({ children, ...props }) => (
  <h3
    className="gel-typography__sidebar-heading"
    {...props}
  >
    { children }
  </h3>
);