import React, { Fragment } from 'react';
import Card from '../Card';
import { LinkHeading } from '../Typography';

const Calendar = ({
  eventWeekday,
  eventDate,
  eventMonth,
  eventYear,

  eventUrl,
  eventTitle,
  eventDesciption,

  eventTimeString,
  eventLocationString,
}) => {
  const showDate = eventDate && eventMonth;
  const showDateBlock = showDate && eventWeekday;

  const shortWeekday = eventWeekday && eventWeekday.slice(0,3);
  const shortMonth = eventMonth && eventMonth.slice(0,3);

  return (
    <Card type="event">
      <LinkHeading
        url={eventUrl}
        dangerouslySetInnerHTML={{ __html: eventTitle }}
      />

      <p className="d-md-none gel-event-more-icon ml-0" />

      <dl>
        { showDate &&
          <Fragment>
            <dt className="sr-only">
              Date
            </dt>
            <dd className="d-md-inline mr-4 ml-0">
              <i className="fal fa-calendar-alt mr-2" aria-hidden="true" />
              { eventDate } { eventMonth }
            </dd>
          </Fragment>
        }
        <dt className="sr-only">
          Time
        </dt>
        <dd className="d-md-inline mr-4 ml-0">
          <i className="fal fa-clock mr-2" aria-hidden="true" />
          { eventTimeString }
        </dd>

        { eventLocationString &&
          <Fragment>
            <dt className="sr-only">
              Location
            </dt>
            <dd className="d-md-inline mr-4 ml-0">
              <i className="fal fa-map-marker-alt mr-2" aria-hidden="true" />
              { eventLocationString }
            </dd>
          </Fragment>
        }
      </dl>

      <p
        className="gef-char-length-100"
        dangerouslySetInnerHTML={{ __html: eventDesciption }}
      />
    </Card>
  );
};

export default Calendar;