import queryString from 'query-string';
import { ajaxFetch, removeTrailingSlash } from '../utils/utils';
import { getSiteOptions } from '../utils/meta';

const preparedFields = (services, regions, type) => {
  let requiredFields = '';
  const andOr = type === 'or' ? '|' : '.';

  services.forEach((service, index) => {
    regions.forEach(region => {
      requiredFields = `${requiredFields}${service}:${region}${andOr}`
    })
    requiredFields = regions.length > 0 ? requiredFields.slice(0, -1) : requiredFields;
    requiredFields = (regions.length > 0 && index !== services.length - 1) ? `${requiredFields}|` : requiredFields;
  })

  return requiredFields;
}

const getRequiredFields = filterBy => {
  const facets = window.doeSearch.facets;
  let services = filterBy.services || facets[0].options.map(item => item.id);
  let regions = filterBy.region || facets[1].options.map(item => item.value);
  let requiredFields = '';

  if (services.length === 0) {
    services = facets[0].options.map(item => item.id)
  }

  if (regions.length === 0) {
    regions = facets[1].options.map(item => item.value);
  }

  if (filterBy.services == undefined && filterBy.region == undefined) {
    return requiredFields;
  } else if (filterBy.services == undefined && filterBy.region && filterBy.region.length === 0) {
    return requiredFields;
  } else if (filterBy.services && filterBy.services.length === 0 && filterBy.region === undefined) {
    return requiredFields;
  } else if (filterBy.services && filterBy.services.length === 0 && filterBy.region && filterBy.region.length === 0) {
    return requiredFields;
    // } else if ((filterBy.services == undefined || filterBy.services.length === 0) && (filterBy.region && filterBy.region.length > 0)) {
    //   requiredFields = preparedFields(services, regions, 'or');
    // } else if ((filterBy.services && filterBy.services.length > 0) && (filterBy.region == undefined || filterBy.region.length === 0)) {
    //   requiredFields = preparedFields(services, regions, 'or');
    // } else {
    // requiredFields = preparedFields(services, regions, 'and');
  } else {
    requiredFields = preparedFields(services, regions, 'or');
  }

  // requiredFields.slice(0, -1)  
  requiredFields.substring(0, requiredFields.length - 1);

  return requiredFields;
}

const buildSearchUrl = (query, queryType, searchIn, filterBy, start, sortParams) => {
  const siteOptions = getSiteOptions();
  const requiredFields = queryType === 'search' ? getRequiredFields(filterBy) : '';
  const format = queryType === 'search' ? 'json' : 'rich';
  const endPoint = queryType === 'search' ? 'app/search' : queryType;

  let params = {
    q: query && query.toLowerCase(),
    // site: siteOptions.collection,
    client: siteOptions.client,
    // getfields: '*',
    requiredfields: requiredFields,
    format
  };

  if (sortParams && queryType === 'search') {
    if (sortParams.asc) { params.sortBy = sortParams.sortBy }
    params.asc = sortParams.asc
  }

  if (start !== null) {
    params.start = start;
  }

  return `${removeTrailingSlash(siteOptions.baseUrl)}/${endPoint}?${queryString.stringify(params)}`;
};

const search = (query, searchIn, filterBy, start, sortParams) => (
  ajaxFetch(
    buildSearchUrl(query, 'search', searchIn, filterBy, start, sortParams)
  )
);

const searchSuggestions = (query, searchIn, filterBy) => {
  return (
    ajaxFetch(
      buildSearchUrl(query, 'suggest', searchIn, filterBy)
    )
  );
}

export { search, searchSuggestions };
