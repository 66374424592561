import React from 'react';
import Card from '../Card';
import { LinkHeading, SubHeading, Paragraph } from '../Typography';

const News = ({ title, url, date, body, image = {} }) => (
  <Card type="article">
    <div className="row">
      <div className="col">
        <LinkHeading url={url}>
          {title}
        </LinkHeading>
        <SubHeading>
          {date}
        </SubHeading>
        <Paragraph dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      { image.src &&
        <div className="col-3">
            <img
              src={image.src}
              alt={image.alt}
            />
        </div>
      }
    </div>
  </Card>
);

export default News;