import React from 'react';
import DocumentItem from '../../components/ResultTypes/Doc';
import { formatHtml } from "../utils";


const getDocumentType = (result) => {
  let oniDocType = result.metatags["oni_doc_type"];
  oniDocType = oniDocType.replace(/(doc|xls|ppt)x/, (match, p1) => p1);
  if (oniDocType === 'html') {
    const { document_type } = result.metatags;
    if (document_type === 'Policy') {
      return 'policy';
    }
  }
  return oniDocType;
};

const getIconStyle = (oniDocType) => {
  switch(oniDocType) {
    case 'pdf':
      return 'file-pdf';
    case 'doc':
      return 'file-word';
    case 'xls':
      return 'file-excel';
    case 'ppt':
      return 'file-powerpoint';
    case 'policy':
      return 'file-certificate';
    default:
      return 'file';
  }
};

const buildDocFragment = (result = {}) => {
  const { metatags = {} } = result;

  const oniDocType = getDocumentType(result);
  const documentIconStyle = getIconStyle(oniDocType);
  const summaryHtml = formatHtml(result.highlightedContentFragments);
  
  const { doe_policy_referenceNumber, security_public, entitytag_det_security } = metatags;
  const isStaffOnly = security_public === 'no' || entitytag_det_security === 'secure';

  const subtitle = doe_policy_referenceNumber
    ? doe_policy_referenceNumber
    : undefined;

  return (
    <li className="search__result-item" key={result.id}>
      <DocumentItem
        summary={summaryHtml}
        title={result.title}
        subtitle={subtitle}
        url={result.url}
        resultType={oniDocType}
        iconStyle={documentIconStyle}
        key={result.id}
        isStaffOnly={isStaffOnly}
      />
    </li>
  );
};

export default buildDocFragment;
