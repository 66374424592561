import React from 'react';

class ErrorPage extends React.Component {

	handleClick = () => {
		this.props.history.push('/');
	}

	render() {
		return (
			<div className="container-fluid pl-0 pr-0">
				<div className="gel-search__banner">
					<div className="container">
						<div className="gel-search__banner__content">
							<div className="row mb-4"></div>
							<h1>Unable to login this time. </h1>
							<button type="button" onClick={() => this.handleClick()} className="btn btn-primary mt-2">Login</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ErrorPage;
