import React from 'react';
import queryString from 'query-string';

import { escapeRegexCharacters, debounce } from '../utils/utils';
import { getSiteOptions } from '../utils/meta';
import { search, searchSuggestions } from '../services/search';
import { clearSession } from '../services/auth';
const SearchContext = React.createContext();

function parseHtmlContent(spelling) {
  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = spelling;
  return tempDiv.textContent || tempDiv.innerText || "";
}

const itemsPerPage = 10;
const defaultFacets = window.sessionStorage.getItem('facets');

const defaultState = {
  query: window.sessionStorage.getItem('searchTerm'),
  lastSearchQuery: '',

  facets: defaultFacets ? JSON.parse(defaultFacets) : {},
  loading: false,
  sortParams: { sortBy: 'title', asc: JSON.parse(window.sessionStorage.getItem('sortAlphabatically')) || false },
  selectedPage: 0,
  start: 0,
  suggestions: [],

  result: undefined,
  didYouMean: undefined,
  didYouMeanHtml: undefined,

  isSecure: false,
  searchInText: 'NSW Department of Health',
  lookingForSomethingElse: undefined,
};


class SearchProvider extends React.Component {
  state = {
    ...defaultState,
  };

  // TODO: only call update if value !== stored value
  onSetQuery = (query, cb) => {
    window.sessionStorage.setItem('searchTerm', query);
    this.setState({ query }, cb);
  }
  onSetStart = (start = 0, cb) => this.setState({ start }, cb);
  onSetPage = (page = 0, cb) => this.setState({
    selectedPage: page,
    start: page * itemsPerPage,
  }, cb);

  onSetSearchInText = (value, cb) => this.setState({
    searchInText: value,
  }, cb);

  onSetLookingForSomethingElse = (value, cb) => this.setState({
    lookingForSomethingElse: value,
  }, cb);

  onSetBackTo = (value, cb) => this.setState({
    backTo: value,
  }, cb);

  onSetIsSecure = (value, cb) => this.setState({
    isSecure: value,
  }, cb);

  onUpdateQueryParams = (options = {}) => {
    const { location } = window;
    const { origin, pathname, search } = location || {};
    const { query, sortParams } = this.state;
    const selectedRegions = this.state.facets.region || []
    const regions = selectedRegions.map(item => {
      return `regions: ${item}|`
    })

    const selectedServices = this.state.facets.services || []
    const services = selectedServices.map(item => {
      return `services: ${item}|`
    })

    const updatedParams = { q: "", requiredFields: `${regions}${services}`, asc: sortParams.asc }
    if (sortParams.asc) { updatedParams.sortBy = sortParams.sortBy }
    updatedParams.q = query && query.toLowerCase();

    const updatedQueryString = queryString.stringify(updatedParams);
    const updatedLocation = `${origin}${pathname}?${updatedQueryString}`;

    const isChanged = (
      JSON.stringify(queryString.parse(search)) !== JSON.stringify(updatedParams)
    );

    if (window.history.replaceState && isChanged) {
      window.history.replaceState({}, '', updatedLocation);
    }

    if (window.dataLayer && isChanged) {
      const { baseUrl, collection, site, client, ignoreSiteForGlobal } = getSiteOptions() || {};
      const { q: query, filterBy, as_sitesearch, ...otherParams } = updatedParams;
      window.dataLayer.push({
        'event': 'searchRefine',
        query,
        filterBy: !!filterBy ? filterBy : 'all',
        as_sitesearch: !!as_sitesearch ? as_sitesearch : 'all',

        baseUrl,
        collection,
        site,
        client,
        ignoreSiteForGlobal,

        ...otherParams,

        path: `${pathname}?${updatedQueryString}`
      });
    }
  };

  onSetFacet = (facetKey, facetValue, cb) => {
    const selectedFacets = this.state.facets[facetKey] || [];
    const uniqueFacets = selectedFacets.includes(facetValue) ? selectedFacets.filter(item => item !== facetValue) : [...selectedFacets, facetValue];

    const facets = {
      ...this.state.facets,
      [facetKey]: uniqueFacets,
    }

    return this.setState({ facets }, () => {
      window.sessionStorage.setItem('facets', JSON.stringify(this.state.facets));
      this.onUpdateQueryParams({ [facetKey]: uniqueFacets });
    })
  };

  onSetSortType = type => {
    return this.setState({ sortParams: { sortBy: 'title', asc: type !== 'Most Relevant' } }, () => {
      window.sessionStorage.setItem('sortAlphabatically', this.state.sortParams.asc);
      this.onSearchFetch(true);
    });
  }

  onClearFilter = () => {
    return this.setState({ facets: {} }, () => {
      window.sessionStorage.removeItem('facets');
      this.onSearchFetch(true);
    });
  }

  onClearResult = () => {
    return this.setState({ query: '' }, () => {
      window.sessionStorage.removeItem('searchTerm');
      this.onSearchFetch(true);
    });
  }

  onSetFacets = (facets, cb) => {
    return this.setState({
      facets: {
        ...this.state.facets,
        ...facets,
      }
    }, cb);
  };

  onGetFacet = (facetKey) => {
    return this.state.facets[facetKey]
  };
  onSearchFetch = (updateQuery, callback) => {
    const { query, lastSearchQuery, start, facets, sortParams } = this.state;
    const { as_sitesearch, filterBy } = facets;

    const searchQuery = typeof query !== 'undefined' ? query : lastSearchQuery;

    if (updateQuery) {
      this.onUpdateQueryParams({ q: searchQuery });
    }

    this.setState({ loading: true });

    search(searchQuery, as_sitesearch, facets, start, sortParams)
      .then((response) => {
        if (response) {
          if (response.status === "403") {
            clearSession();
            window.location.reload();
          } else {
            this.setState({
              lastSearchQuery: searchQuery,
              result: response,
              didYouMean: response.didYouMean ? response.didYouMean["suggest"] : '',
              didYouMeanHtml: response.didYouMean ? parseHtmlContent(response.didYouMean["highlightedSuggest"]) : '',
              loading: false,
            }, callback);
          }
        }
      })
      .catch((error) => {
        this.setState({
          error,
          loading: false,
        }, callback);
      });
  };

  onResetSearch = () => {
    this.setState({ ...defaultState });
  };

  onSuggestionsFetch = debounce(() => {
    const { query = '', facets } = this.state;
    const { as_sitesearch, filterBy } = facets;

    const escapedValue = escapeRegexCharacters(query.trim());

    if (escapedValue === '') {
      return this.setState({ suggestions: [] });
    }


    searchSuggestions(escapedValue, as_sitesearch, filterBy)
      .then((response) => {
        if (response.status === "403") {
          clearSession();
          window.location.reload();
        } else {
          const result = response.results ? response.results.map(item => item.name) : [];
          this.setState({ suggestions: result });
        }
      })
      .catch((error) => {
        this.setState({ error });
        console.error(error)
      });
  }, 300);

  onSuggestionsClear = () => this.setState({ suggestions: [] });

  render() {
    const {
      query,
      lastSearchQuery,
      suggestions,
      result,
      selectedPage,
      facets,
      sortParams,
      didYouMean,
      didYouMeanHtml,
      loading,
      isSecure,
      searchInText,
      lookingForSomethingElse,
      backTo
    } = this.state;

    const { children } = this.props;

    return (
      <SearchContext.Provider
        value={{
          query,
          lastSearchQuery,

          suggestions,
          result,
          selectedPage,
          facets,
          sortParams,
          didYouMean,
          didYouMeanHtml,

          loading,

          isSecure,
          searchInText,
          lookingForSomethingElse,

          onSetQuery: this.onSetQuery,
          onSetStart: this.onSetStart,
          onSetPage: this.onSetPage,
          onSetFacet: this.onSetFacet,
          onSetSortType: this.onSetSortType,
          onClearFilter: this.onClearFilter,
          onClearResult: this.onClearResult,
          onSearchFetch: this.onSearchFetch,
          onSuggestionsFetch: this.onSuggestionsFetch,
          onSuggestionsClear: this.onSuggestionsClear,
          onResetSearch: this.onResetSearch,
          onGetFacet: this.onGetFacet,
          onSetFacets: this.onSetFacets,

          onSetSearchInText: this.onSetSearchInText,
          onSetLookingForSomethingElse: this.onSetLookingForSomethingElse,
          onSetIsSecure: this.onSetIsSecure,

          onSetBackTo: this.onSetBackTo,
          backTo,
        }}
      >
        {children}
      </SearchContext.Provider>
    )
  }
}

export default {
  Provider: SearchProvider,
  Consumer: SearchContext.Consumer,
  Context: SearchContext,
}
