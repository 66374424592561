import React from 'react';
import ResultType from '../ResultType';

const School = (props) => {

  let src = `//maps.googleapis.com/maps/api/staticmap?center=${props.street}, ${props.suburb} ${props.state} ${props.postcode}&zoom=17&size=640x175&maptype=roadmap&markers=color:red|${props.latitude},${props.longitude}&key=AIzaSyBaDkytMPJViZBoLduwXbHyUHdXOdvTJB0`;

  return (
    <div className="gel-card">
      <div className="row no-gutter">
        <div className="col-12 col-md-6">
          <div className="gel-card__header">
            <ResultType type="school" />
          </div>
          
          <div className="gel-card__body">
              <h2 className="gel-typography__link-heading">
                <a href={props.url}>{props.title}</a>
              </h2>
              <p className="gel-search-results__url mb-3">{props.url}</p>
              <p className="mb-3">
                {props.street}<br />
                {props.suburb} {props.state} {props.postcode}
              </p>
              <p>
                (02) {props.phone}<br />
                <a href={"mailto:" + props.email}>{props.email}</a>
              </p>
          </div> 
        </div>
        <div className="col pl-md-0">
          <div className="gel-map__container">
            <img
              className="gel-map__image"
              src={src}
              aria-label={"Map of " + props.title}
              alt={"Map of " + props.title}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default School;