import React from 'react';
import News from '../../components/ResultTypes/News';
import { formatHtml } from "../utils";

const buildMapFragment = (result) => {
  const {
    DCTERMS_date_published,
  } = result.metatags;
  const summaryHtml = formatHtml(result.highlightedContentFragments);
  
  let publishDate = undefined;
  if (DCTERMS_date_published) {
    // Safari requires date time string to have a 'T' instead of space
    // Z indicated local timezone
    const dateString = `${DCTERMS_date_published.replace(/ /g,"T")}Z`;
    publishDate = new Date(dateString);
  }

  const dateFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  return (
    <li className="search__result-item" key={result.id}>
      <News
        title={result.title}
        url={result.url}
        date={publishDate && publishDate.toLocaleDateString("en-AU", dateFormatOptions)}
        body={summaryHtml}
      />
    </li>
  );
};

export default buildMapFragment;
