import React from 'react';
import ResultType from '../ResultType';

const Card = ({ type, children }) => (
  <div className="gel-card">
    {type &&
      <div className="gel-card__header">
        <ResultType type={type} />
      </div>
    }
    <div className="gel-card__body">
      {children}
    </div>
  </div>
);

export default Card;
