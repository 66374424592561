import React from 'react';
import cx from 'classnames';

class ShowHide extends React.Component {
  state = {
    show: false,
    collapsing: false,
  };


  toggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };


  render() {
    const { children, title } = this.props;
    const { show, collapsing } = this.state;

    return (
      <aside
        className="gel-show-hide mt-2 mb-3"
        role="tablist"
        aria-label="mobileResults"
      >
        <div className="card">
          <div
            className={cx('card-header', 'pl-0', 'pr-0', 'mr-0')}
            id="heading0"
          >
            <h3 className="gel-show-hide_title">
              <button
                className="gel-show-hide_link btn-block collapsed"
                aria-expanded={show}
                onClick={this.toggle}
              >
                <i /><i className="vertical" /> {title}
              </button>
            </h3>
          </div>
          <div
            className={cx(
              collapsing ? 'collapsing' : 'collapse',
              show && !collapsing && 'show',
            )}
            id="collapse-0"
            aria-labelledby="heading0"
          >
            <div className="card-body ml-0 mr-0">
              {children}
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

export default ShowHide;