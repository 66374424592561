import React from 'react';
import Card from '../Card';
import { LinkHeading, SubHeading, Paragraph } from '../Typography';

const Document = ({ resultType, url, title, subtitle, summary, isStaffOnly }) => {
  return (
    <Card type={resultType}>
      <div className="overflow-hidden">
        <LinkHeading
          url={url}
          dangerouslySetInnerHTML={{ __html: title }}
          isStaffOnly={isStaffOnly}
          style={{ overflowWrap: 'break-word' }}
        />
        <SubHeading>
          { subtitle }
        </SubHeading>
        <Paragraph dangerouslySetInnerHTML={{ __html: summary }} />
        <Paragraph
          dangerouslySetInnerHTML={{ __html: url }}
          style={{ overflowWrap: 'break-word' }}
        />
      </div>
    </Card>
  );
};

export default Document;