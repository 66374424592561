import React from 'react';
import placeholder from './../../images/placeholder.png';

const getServiceRegions = regions => {
	const keys = Object.keys(regions);
	return keys && keys.map((item, index) => {
		const region = regions[item];
		return index + 1 === keys.length ? region : `${region} - `;
	})
};

const getClassName = (icon) => {
	return `fas ${icon} pr-2`
}

const getContactItem = (icon, value) => {
	return (
		<div style={{ display: 'flex', overflow: 'hidden', cursor: 'pointer' }} className="pb-1" data-placement="top" data-toggle="tooltip" title={value}>
			<i className={getClassName(icon)} />
			<span className="ml-100 small">{value}</span>
		</div>
	)
}

const ResultCard = ({ result, handleClick }) => {
	const rawContacts = JSON.parse(JSON.stringify(result.fields.contacts));
	const contacts = JSON.parse(rawContacts)[0] || {};
	const logoUrl = result.fields.logo || placeholder;

	return (
		<div className="card" >
			<img src={logoUrl} className="card-img-top border-bottom logo" alt="" />
			<div className="card-body">
				<h5 className="card-title">{result.title}</h5>
				<p className="card-text">{result.fields.serviceDescription}</p>
				<p style={{ fontSize: 12 }}>
					{getServiceRegions(result.fields.regions)}
				</p>
				<div >
					<h6 className="card-subtitle mb-3 mt-4">Key Contacts</h6>
					{contacts.name && getContactItem('fa-user', contacts.name)}
					{contacts.telephone && getContactItem('fa-phone', contacts.telephone)}
					{/* {contacts.email && getContactItem('fa-envelope', contacts.email)} */}
					{contacts.email && (
						<div style={{ display: 'flex', overflow: 'hidden', cursor: 'pointer' }} className="pb-1">
							<i className={getClassName("fa-envelope")} />
							<a href={"mailto:" + contacts.email} className="ml-100 small" target="_blank" title={contacts.email}>{contacts.email}</a>
						</div>
					)
					}
				</div>
				<button type="button" onClick={() => handleClick(result)} className="btn btn-primary mt-2">Read More</button>
			</div>
		</div >
	);
}

export default ResultCard;
