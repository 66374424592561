import React from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';

import App from './App';
// import registerServiceWorker from './registerServiceWorker';

const searchEl = document.getElementById('search-spa');

if (searchEl) {
  const queryParams = queryString.parse(window.location.search);
  ReactDOM.render(
    <App queryParams={queryParams} />,
    searchEl
  );
}

// registerServiceWorker();
