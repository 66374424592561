/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */

import React, { Component, Fragment } from "react";
import isMobile from 'ismobilejs';

import Sidebar from "./Sidebar";
import SearchResults from "./SearchResults";

import SearchContext from '../shared/SearchContext.js';
import SearchBox from './SearchBox';
import LoadingIndicator from '../components/LoadingIndicator';
import Badge from '../components/Badge';
import { getUserInfo } from './../services/auth'

const focusInputOnSuggestionClick = !isMobile.any;

class Search extends Component {
  static contextType = SearchContext.Context;

  execDidYouMean = async () => {
    const { didYouMean, onResetSearch, onSearchFetch, onSetQuery } = this.context;
    await onResetSearch();
    await onSetQuery(didYouMean);
    await onSearchFetch(true);
  };

  isPrivate = () => {
    const { isSecure } = this.context;

    if (typeof isSecure === 'undefined') {
      const metaElements = document.getElementsByName("security.public");
      return (
        metaElements.length > 0 &&
        metaElements[0].content.indexOf('no') >= 0
      );
    }

    return isSecure;
  }

  async componentDidMount() {
    const { doeSearch } = window;
    const {
      facets = [],
      isStaffOnly,
      searchingIn,
      lookingForSomethingElse,
      backTo,
    } = doeSearch || {};

    // getUserInfo().then(response => {
    //   console.log(response);
    // }).catch(err => {
    //   console.log(err)
    // });    

    const { queryParams = {} } = this.props;
    const {
      onSetPage,
      onSetQuery,
      onSearchFetch,
      onSetFacets,
      onSetIsSecure,
      onSetSearchInText,
      onSetLookingForSomethingElse,
      onSetBackTo,
    } = this.context;

    await onSetPage();
    await onSetQuery(queryParams.q || window.sessionStorage.getItem('searchTerm') || '');

    //TODO: Check if facets needs to be set
    // const facetValues = facets.reduce((acc, val) => ({ ...acc, [val.id]: queryParams[val.id] }), {});    
    // await onSetFacets(facetValues);

    await onSetIsSecure(isStaffOnly);
    await onSetSearchInText(searchingIn);
    await onSetLookingForSomethingElse(lookingForSomethingElse);
    await onSetBackTo(backTo);

    if (queryParams.q) {
      await onSearchFetch(true);
    }
    await onSearchFetch(true);
  }

  render() {
    const { loading } = this.context;

    return (
      <Fragment>
        <div className="container-fluid pl-0 pr-0">
          <div className="gel-search__banner">
            <div style={{ clear: "both" }} className="container">
              <div className="row pt-4 mb-4">
                <div className="col pt-3">
                  <h1 className="search__heading">
                    Specialist allied health services for schools
                    </h1>
                </div>
              </div>
              <div className="gel-search__banner__content">
                <div className="row mb-4">
                  <div className="col">
                    <SearchBox
                      focusInputOnSuggestionClick={focusInputOnSuggestionClick}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="row d-md-none pt-3 text-dark"> */}
              <div className="row d-md-none">
                <div className="col">
                  <Sidebar.Mobile />
                </div>
              </div>
            </div>
          </div>
        </div>

        <article className="container pt-3">
          <div className="row">
            <aside className="col-4 d-none d-md-block mt-5" aria-label="desktopResults">
              <Sidebar.Desktop />
            </aside>
            <div className="col-12 col-md-8">
              {loading && <LoadingIndicator />}
              {!loading && <SearchResults handleResultClick={(result) => {
                this.props.history.push(`profile/${result.fields.tradingName || ''}`, {
                  result
                });
              }} />}
            </div>
          </div>
        </article>
      </Fragment>
    );
  }
}

export default Search;
