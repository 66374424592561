// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const ajaxFetch = (url, options) => {
    // perform a cors pre-flight request and return a promise          
    return fetch(url).then(response => response.json());
    //return window.fetch(url, Object.assign({ mode: "cors" }, options)).then(response => response.json());
};

const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

const removeTrailingSlash = (str) => {
    return str.replace ? str.replace(/\/$/, "") : '';
};

const formatHtml = (htmlFragments) => (
    htmlFragments
        .map((t) => t.trim())
        .join("...")
        .replace(/\n/g, "<br>")
);

const parseJwt = token => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));


    return JSON.parse(jsonPayload);
};

export { escapeRegexCharacters, ajaxFetch, debounce, removeTrailingSlash, formatHtml, parseJwt };
