import React from 'react';
import SchoolItem from '../../components/ResultTypes/School';

const buildMapFragment = (result) => {
  const {
    doe_contact_email,
    doe_contact_phone,
    doe_contact_url,
    doe_location_street,
    doe_location_suburb,
    doe_location_state,
    doe_location_postcode,
    doe_geo_latitude,
    doe_geo_longitude
  } = result.metatags;

  return (
    <li className="search__result-item" key={result.id}>
      <SchoolItem
        title={result.title}
        url={doe_contact_url}
        email={doe_contact_email}
        phone={doe_contact_phone}
        street={doe_location_street}
        suburb={doe_location_suburb}
        state={doe_location_state}
        postcode={doe_location_postcode}
        latitude={doe_geo_latitude}
        longitude={doe_geo_longitude}
      />
    </li>
  );
};

export default buildMapFragment;
