import React from 'react';

const ResultHeader = ({ message, query }) => (
  <p
    id="gel-search-results-nav"
    className="gel-search-results__info mt-2 mb-2"
  >
    {message} <strong>{query}</strong>
  </p>
);

export default ResultHeader;
