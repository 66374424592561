import React from 'react';
import Login from './Login';
import Profile from './Profile';
import ErrorPage from './ErrorPage';
import Search from '../containers/Search';
import { getSiteOptions } from '../utils/meta';
import { isAuthorizedUser } from '../services/auth';
import SearchContext from '../shared/SearchContext';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

const siteOptions = getSiteOptions();

const PrivateRoute = ({ component: Component, ...rest }) => {
	if (isAuthorizedUser()) {
		return <Route {...rest} component={Component} />;
	} else {
		window.location.href = siteOptions.ssoUrl;
		return null;
	}
}

const Router = () => {
	return (
		<BrowserRouter>
			<SearchContext.Provider>
				<Switch>
					<PrivateRoute exact path={'/'} component={Search} />
					<PrivateRoute path={'/profile/:providerName'} component={Profile} />
					<Route path={'/login/callback'} component={Login} />
					<Route path={'/error'} component={ErrorPage} />
				</Switch>
			</SearchContext.Provider>
		</BrowserRouter>
	)
}

export default Router;
