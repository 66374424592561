import React from 'react';

const types = {
  school: ['fas', 'fa-graduation-cap', 'School'],
  pdf: ['fal', 'fa-file-pdf', 'PDF'],
  doc: ['fal', 'fa-file-word', 'Word doc'],
  xls: ['fal', 'fa-file-excel', 'Excel doc'],
  ppt: ['fal', 'fa-file-powerpoint', 'PowerPoint Presentation'],
  policy: ['fal', 'fa-file-certificate', 'Policy'],
  file: ['fal', 'fa-file', 'File'],
  web: ['fas', 'fa-globe-asia', 'Web Page'],
  article: ['fal', 'fa-newspaper', 'News'],
  event: ['fal', 'fa-calendar-alt', 'Event']
}

const ResultType = ({ type }) => {
  const [fontFamily, icon, label] = types[type] || types['file'];

  return (
    <div className="gel-search__item__result-type">
      <span
        className={`${fontFamily} ${icon} mr-2 gel-result-type-icon`}
        aria-hidden="true"
      />

      {label}
    </div>
  )
}

export default ResultType;