import React from 'react';

const Badge = ({ icon, label, marginLeft = 5, marginRight = 0 }) => {
  return (
    <span className={`badge badge-secondary ml-${marginLeft} mr-${marginRight}`}>
      { icon &&
        <i className={`fa fa-${icon} mr-1`} aria-hidden="true" />
      }
      { label }
    </span>
  );
};

export default Badge;