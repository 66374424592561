import React from 'react';
import Autosuggest from 'react-autosuggest';
import autosuggestStyles from './autosuggest.module.css';
import SearchContext from '../../shared/SearchContext';

const getSuggestionValue = suggestion => suggestion;

const renderSuggestion = (searchQuery = '') => (suggestion = '') => {
  const escapedSearchQuery = searchQuery.replace(/[^a-zA-Z ]/g, '');
  const expression = new RegExp(escapedSearchQuery, 'gi')
  const editedSuggestion = suggestion.replace(expression, (foundText) => `<b>${foundText}</b>`)
  return (
    <span dangerouslySetInnerHTML={{ __html: editedSuggestion }} />
  );
};

class SearchBox extends React.PureComponent {
  static contextType = SearchContext.Context;
  handleQueryChange = (e, { newValue }) => this.context.onSetQuery(newValue);

  onSuggestionSelected = async (e) => {
    await this.context.onSetPage();
    await this.context.onSearchFetch(true);
  };

  onSearchFormSubmit = async (e) => {
    e.preventDefault();
    await this.context.onSetPage();
    await this.context.onSearchFetch(true)
  };

  render() {
    const { focusInputOnSuggestionClick } = this.props;

    const {
      query,
      suggestions,
      onSuggestionsFetch,
      onSuggestionsClear,
    } = this.context;

    return (
      <form
        id="search-box"
        aria-hidden="false"
        aria-labelledby="btnSearch2"
        onSubmit={this.onSearchFormSubmit}
      >
        <fieldset className="">
          <legend className="show-on-sr">Global Search</legend>
          <label htmlFor="search" className="show-on-sr">
            Enter your search
          </label>
          <div className="gel-searchbox">
            <div className="form-group">
              <div className="input-group">
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetch}
                  onSuggestionsClearRequested={onSuggestionsClear}
                  onSuggestionSelected={this.onSuggestionSelected}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion(query)}
                  inputProps={{
                    placeholder: "Search",
                    value: query || '',
                    onChange: this.handleQueryChange,
                  }}
                  focusInputOnSuggestionClick={focusInputOnSuggestionClick}
                  theme={{
                    container: 'input-group',
                    input: 'form-control search__autosuggest__input',
                    suggestionsContainer: 'search__autosuggest__dropdown',

                    suggestionsList: 'search__autosuggest__suggestions',
                    suggestion: 'search__autosuggest__suggested-item',
                    suggestionHighlighted: 'highlight'
                  }}
                />
                <div className="input-group-append">
                  <button className="btn gel-button"><span className="fas fa-search"></span></button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    );
  }
}

export default SearchBox;
