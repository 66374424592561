import React, { Fragment } from 'react';
import SearchContext from '../../shared/SearchContext';

import Facets from "../../components/Facets";

class Sidebar extends React.PureComponent {
  static contextType = SearchContext.Context;

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="gel-typography__sidebar-heading mt-2 mb-3">Filter Results</h2>
          <button className="btn btn-secondary btn-sm h-25 d-inline-block" type="button" onClick={this.context.onClearFilter} aria-expanded="false">
            Clear
          </button>
        </div>
        <hr className="gel-hr-search" />

        <form action="" method="GET">
          <Facets location="desktop" />
        </form>
      </Fragment>
    );
  }
}

export default Sidebar;
