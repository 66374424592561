import React from 'react';
import Facets from "../../components/Facets";
import ShowHide from "../../components/ShowHide";
import SearchContext from '../../shared/SearchContext';

class Sidebar extends React.PureComponent {
  static contextType = SearchContext.Context;

  render() {
    return (
      <ShowHide title="Filter Results">
        <div className="pt-2">
          <form action="" method="GET">
            <Facets location="mobile" />
          </form>
        </div>
        <hr className="gel-hr-light" />
      </ShowHide>
    );
  }
}

export default Sidebar;
