import React from 'react';
import HtmlFragment from '../../components/ResultTypes/HtmlFragment';
import { formatHtml } from "../utils";

const buildSummaryHtmlFragment = (result) => {
  const security_meta = result.metatags["security_public"];
  const isStaffOnly = security_meta === 'no';
  const summaryHtml = formatHtml(result.highlightedContentFragments);
  return (
    <li className="search__result-item" key={result.id}>
      <HtmlFragment
        result={result}
        summaryHtml={summaryHtml}
        isStaffOnly={isStaffOnly}
      />
    </li>
  );
};

export default buildSummaryHtmlFragment;
