/* CONSTANTS */
const DAYS_OF_WEEK = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

const MONTHS_OF_YEAR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];


/* FUNCTIONS */
export const isValidDate = (val) => {
  return !!Date.parse(val);
}

export const getDayOfWeek = (index) => {
  if (!index) { return undefined };
  return DAYS_OF_WEEK[index];
}

export const getDayAbbreviation = (index) => {
  if (!index) { return undefined };
  return DAYS_OF_WEEK[index].splice(0,3);
}

export const getMonthOfYear = (index) => {
  if (!index) { return undefined };
  return MONTHS_OF_YEAR[index];
}

export const getMonthAppreviation = (index) => {
  if (!index) { return undefined };
  return MONTHS_OF_YEAR[index].split(0,3);
}