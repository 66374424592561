import React from 'react';
import qs from 'qs';
import { login, clearSession } from '../../services/auth';
import { parseJwt } from '../../utils/utils';

const Login = ({ history }) => {
	const url = window.location.href;
	const token = url.split('?').pop();
	const parsedToken = qs.parse(token, { ignoreQueryPrefix: true });

	if (parsedToken.code) {
		login(parsedToken.code).then(response => {
			const givenName = parseJwt(response.data.id_token).givenName;

			if (response.status === 'ok') {
				window.sessionStorage.setItem('access_token', response.data.access_token);
				window.sessionStorage.setItem('user_name', givenName);
				history.push('/');
			} else {
				clearSession();
				history.push('/error');
			}

			window.location.reload();
		}).catch(err => {
			clearSession();
			history.push('/error');
			window.location.reload();
		});
	} else {
		history.push('/error');
		window.location.reload();
	}
}

export default Login;
