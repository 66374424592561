import React from 'react';
import { Radio } from '../Input';
import CheckBox from '../Input/CheckBox';

const FilterFacet = ({
  parentId,
  facetKey,
  facetName,
  options = {},
  onSelect,
  value: selectedValue,
  displayLabel,
}) => (
    <div>
      <fieldset className="form-group">
        <legend className={displayLabel ? 'form-label pl-0 pb-1' : 'sr-only'}>
          {displayLabel ? displayLabel : `Filter By ${facetName}`}
        </legend>
        {options.map(({ id, value, label }) => (
          <CheckBox
            key={`${parentId ? `${parentId}_` : ''}filter_${facetKey}_${id}`}
            id={`${parentId ? `${parentId}_` : ''}filter_${facetKey}_${id}`}
            name={`filter_${facetKey}`}
            value={value}
            onChange={(e) => onSelect(e.target.value)}
            checked={selectedValue.includes(value)}
            label={label}
          />
        ))}
      </fieldset>
    </div>
  );

export default FilterFacet;
