import React from 'react';
import Card from '../Card';
import StaffOnlyItem from './StaffOnly';

const getAddresses = (result) => {

}

const HtmlFragment = ({ result, summaryHtml, isStaffOnly }) => (
  <Card>
    <img
      src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=300"
      alt="new"
    />

    <h2 className="gel-typography__link-heading">
      <a
        className="mr-6"
        href={result.url}
        dangerouslySetInnerHTML={{ __html: result.title }}
      />
      {isStaffOnly
        ? <StaffOnlyItem />
        : null
      }
    </h2>
    <p dangerouslySetInnerHTML={{ __html: summaryHtml }} />
    <p
      className="gel-search-results__url"
      dangerouslySetInnerHTML={{ __html: result.url }}
    />
  </Card>
);

export default HtmlFragment;
