import React, { Fragment } from 'react';
import FilterBy from './FilterBy';
import SearchContext from '../../shared/SearchContext';

class Facets extends React.Component {
  static contextType = SearchContext.Context;

  handleFacetChange = (facetKey) => async (value) => {
    const { onSetFacet, onSetPage, onSearchFetch } = this.context;
    await onSetFacet(
      facetKey,
      value,
    );
    await onSetPage(0);
    await onSearchFetch();
  };

  getSelected = (facetKey) => this.context.onGetFacet(facetKey);

  render() {
    const { doeSearch } = window;
    const { facets = [] } = doeSearch || {};

    return (
      <Fragment>
        {facets.map((facet) => (
          <div className="mb-4" key={facet.id}>
            <FilterBy
              parentId={this.props.location}
              facetKey={facet.id}
              facetName={facet.name}
              options={facet.options || []}
              onSelect={this.handleFacetChange(facet.id)}
              value={this.getSelected(facet.id) || facet.defaultValue}
              displayLabel={facet.label}
            />
          </div>
        ))}
      </Fragment>
    );
  }
}

export default Facets;
