/* eslint-disable no-unused-vars */

import React from 'react';
import { isValidDate, getDayOfWeek, getMonthOfYear } from '../date';
import CalendarItem from '../../components/ResultTypes/Calendar';

const isLocationSchool = (locationStr = '') => {
  if (!locationStr) { return false };
  const str = locationStr.trim();
  return !!str.match(/^school/gi);
}

const isAllDay = (timeStr = '') => {
  if (!timeStr) { return false };
  return !!timeStr.match(/((all day)|(school day)|(school hours))/i)
}

const buildEventFragment = ({ id, url, title, metatags }) => {
  const {
    doe_event_start_date,
    doe_event_end_date,

    doe_event_start_time = '',
    doe_event_end_time = '',
    
    doe_event_location,
    doe_event_description,

    doe_event_start_dtstamp,
    doe_event_end_dtstamp,

    doe_orgunit,
  } = metatags;


  const startDateTimeStamp = isValidDate(doe_event_start_date)
    ? Date.parse(doe_event_start_date)
    : parseInt(doe_event_start_dtstamp);

  const eventStartDate = startDateTimeStamp && new Date(startDateTimeStamp);

  let eventWeekday, eventDate, eventMonth, eventYear, eventTimeString, eventLocationString = '';

  if (eventStartDate) {
    eventWeekday = getDayOfWeek(eventStartDate.getDay());
    eventDate = eventStartDate.getDate();
    eventMonth = getMonthOfYear(eventStartDate.getMonth());
    eventYear = eventStartDate.getFullYear();

    if (doe_event_start_time) {
      eventTimeString = doe_event_start_time;

      if (doe_event_start_time !== doe_event_end_time) {
        eventTimeString = `${doe_event_start_time} - ${doe_event_end_time}`
      }

      if (doe_event_start_time === doe_event_end_time) {
        eventTimeString = doe_event_start_time;
      }

      if (isAllDay(doe_event_start_time)) {
        eventTimeString = 'All Day';
      }
    }
  }

  eventLocationString = !isLocationSchool(doe_event_location)
    ? doe_event_location
    : doe_orgunit;

  return (
    <li className="search__result-item" key={id}>
      <CalendarItem
        eventWeekday={eventWeekday}
        eventDate={eventDate}
        eventMonth={eventMonth}
        eventYear={eventYear}
      
        eventUrl={url}
        eventTitle={title}
        eventDesciption={doe_event_description}
    
        eventTimeString={eventTimeString}
        eventLocationString={eventLocationString}
      />
    </li>
  )
};

export default buildEventFragment;
