import React from 'react';

const CheckBox = ({ id, name, value, checked, disabled, label, onChange, }) => (
	<div className="form-check">
		<input
			className="form-check-input"
			type="checkbox"
			id={id}
			name={name}
			value={value}
			checked={checked}
			disabled={disabled}
			onChange={onChange}
		/>
		<label
			className="form-check-label"
			htmlFor={id}
		>
			{label}
		</label>
	</div>
);

export default CheckBox;
