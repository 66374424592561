/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */

import React, { Fragment } from 'react';
import ReactPaginate from 'react-paginate';

import { Recommended, RecommendedItem } from '../../components/Recommended';
import SearchContext from '../../shared/SearchContext';

import ResultsHeader from '../../components/ResultsHeader';
import LookElsewhere from '../../components/LookElsewhere';

import {
  buildEventFragment,
  buildMapFragment,
  buildDocFragment,
  buildSummaryHtmlFragment,
  buildNewsFragment,
} from '../../utils/resultBuilder';
import ResultCard from '../../components/ResultCard/ResultCard';
import Profile from '../../components/Profile';

class SearchResults extends React.Component {
  static contextType = SearchContext.Context;

  constructor(props) {
    super(props);

    this.state = {
      isResultSelected: false,
      selectedResult: null,
    }
  }

  handlePageClick = async (data) => {
    await this.context.onSetPage(data.selected);
    await this.context.onSearchFetch(false, () => {
      document.getElementById("gel-search-results-nav").focus();
      window.scrollTo(0, 0);
    });
  };

  getResultType = (metadata = {}) => {
    const {
      doe_document_type,
      document_type,
      oni_type,
      doe_orgunit_type,
      DCTERMS_identifier,
    } = metadata;

    if (doe_document_type === 'event' || doe_document_type === 'orgunit') {
      return doe_document_type;
    }

    if (document_type === 'Policy') {
      return 'doc';
    }

    if (DCTERMS_identifier && DCTERMS_identifier.match(/\/news\//i)) {
      return 'news';
    }

    if (oni_type === 'file') {
      return 'doc';
    }

    if (doe_orgunit_type === 'school') {
      return 'orgunit';
    }



    return 'default';
  };

  getFragmentFromResult = (resultType, result) => {
    switch (resultType) {
      case 'event':
        return buildEventFragment(result);
      case 'orgunit':
        return buildMapFragment(result);
      case 'doc':
        return buildDocFragment(result);
      case 'news':
        return buildNewsFragment(result);
      case 'default':
        return buildSummaryHtmlFragment(result);
      default:
        return;
    }
  };

  handleResultClick = (result) => {
    this.setState({ selectedResult: result }, () => this.props.handleResultClick(result));
  }

  processResults = results => {
    return (
      results && results.map(item => (
        <div className="result mt-4" key={item.id}>
          <ResultCard
            result={item}
            handleClick={(item) => this.handleResultClick(item)}
          />
        </div>
      ))
    );
  }

  processResults1 = (results) => results.reduce((acc, result) => {
    const resultType = this.getResultType(result.metatags);
    const fragment = this.getFragmentFromResult(resultType, result);
    return fragment ? [...acc, fragment] : [...acc]
  }, []);


  render() {
    const { lastSearchQuery, result, selectedPage, onSetSortType, lookingForSomethingElse, sortParams } = this.context;

    if (!result) { return null; }

    const { keymatches, from, results, totalHits, size } = result;

    const message = totalHits
      ? `Showing ${from + 1} - ${from + results.length} of ${totalHits || ''} results`
      : `0 results`;

    const resultsMessage = lastSearchQuery === '' ? message : `${message} for`

    const resultItems = this.processResults(results || []);

    const pagination = {
      hasMorePages: totalHits > size,
      showPages: 3,
      afterEllipsisPages: 1,
      pageCount: totalHits % size === 0 ? totalHits / size : Math.ceil(totalHits / size)
    };

    const sortingType = sortParams && sortParams.asc ? "Alphabetically" : "Most Relevant";

    return (
      <Fragment>
        <div className="gel-search-results" id="searchResults" role="region" aria-live="polite">

          <div className="row m-1 pb-3 border-bottom d-flex justify-content-between align-items-center">
            <div className="col-7 pl-0">
              <ResultsHeader
                message={resultsMessage}
                query={lastSearchQuery}
              />
            </div>
            <div className="col-5 pr-0">
              <div className="row">
                <div className="col-8">
                  <div className="dropdown ml-2">
                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {sortingType}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <span className="dropdown-item" onClick={() => onSetSortType("Alphabetically")} style={{ cursor: 'pointer' }}>Alphabetically</span>
                      <span className="dropdown-item" onClick={() => onSetSortType("Most Relevant")} style={{ cursor: 'pointer' }}>Most Relevant</span>
                    </div>
                  </div>
                </div>
                <div className="col-4 pr-0">
                  <button className="btn btn-secondary btn-sm d-inline-block" type="button" onClick={this.context.onClearResult} aria-expanded="false">
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <ol className="gel-search__results mt-4 two-columns"> */}
          <div className="row result-container">
            {resultItems}
          </div>
          {/* </ol> */}
        </div>

        {
          pagination.hasMorePages ?
            <nav>
              <ReactPaginate
                previousLabel={<span>Previous</span>}
                nextLabel={<span>Next</span>}
                breakLabel={<a href="java" onClick={(e) => e.preventDefault}>...</a>}
                breakClassName="gel-gel-pagination__more gel-pagination__disabled"
                breakLinkClassName="gef-remove-external-link gel-remove-external-link"
                pageCount={pagination.pageCount}
                marginPagesDisplayed={pagination.afterEllipsisPages}
                pageRangeDisplayed={pagination.showPages}
                onPageChange={this.handlePageClick}
                containerClassName="gel-pagination"
                activeClassName="active gel-pagination__active"
                disabledClassName="gel-pagination__disabled"
                forcePage={selectedPage}
                pageClassName=""
                pageLinkClassName="gef-remove-external-link gel-remove-external-link"
                previousClassName="gel-pagination__prev"
                nextClassName="gel-pagination__next"
                previousLinkClassName={"gef-remove-external-link gel-remove-external-link"}
                nextLinkClassName={"gef-remove-external-link gel-remove-external-link"}
              />
            </nav>
            : null
        }
      </Fragment >
    );

  }
}

export default SearchResults;

