const getSiteOptions = () => {
    const { doeSearch = {} } = window;
    const { endpoint, siteUrl, collection, ignoreSiteForGlobal, ssoUrl } = doeSearch;

    return {
        baseUrl: endpoint || process.env.BASE_SEARCH_URL,
        ahsLogoutUrl: ssoUrl || process.env.AHS_LOGOUT_URL,
        ssoUrl: process.env.SSO_URL,
        // collection: collection || document.head.getAttribute("data-site-collection") || process.env.DEFAULT_COLLECTION || 'public_education_nsw_gov_au',
        // site: siteUrl || document.head.getAttribute("data-site-url") || process.env.DEFAULT_SITE || '',
        client: process.env.DEFAULT_CLIENT || 'ahs',
        ignoreSiteForGlobal: ignoreSiteForGlobal || !!document.head.getAttribute("data-ignore-site-for-global"),
    };
};

export { getSiteOptions };